import React from 'react';

import { Header } from "./header";
import { WelcomeArea } from "./welcome_area";
import { ICOArea } from "./ico_area";
import { TokenCharts } from "./token_charts"
import { AppListArea } from "./applist_area";
import { RoadmapArea } from "./roadmap_area";
import { TeamProfile } from "./team_profile";
import { SocialMediaArea } from "./social_media_area";
import { ContactFooterArea } from "./contact_footer_area";

function Home() {
    return (
      <><Header /><WelcomeArea /><AppListArea /><ICOArea /><TokenCharts /><RoadmapArea /><TeamProfile /><SocialMediaArea /><ContactFooterArea /></>
    );
}

export default Home;