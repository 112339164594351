import React, { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { AppContext } from "./../../index";
import { getSweepstakesData } from "./../validera/welcome_area";
import { getAddress } from "@harmony-js/crypto";
import { config } from "../validera/welcome_area";

export function DrawDetails() {
  const { state, dispatch } = useContext(AppContext);

  const [items, setItems] = useState([]);

  function createEpochDropdownOptions() {
    if (items.length > 0) return;

    let itemList = [];

    
    for (let i = state.latestDrawNo; i > state.latestDrawNo - 30; i--) {
      if (i < config.swp_start_epoch_from) {
        return itemList;
      }

      const latest = i == state.latestDrawNo ? "(Current)" : "";

      itemList.push(
        <option key={i} value={i}>
          Epoch {i} {latest}
        </option>
      );

      setItems(itemList);
    }
  }

  function handleEpochDropdownOnChange(e) {
    document.getElementById("searchField").setAttribute("disabled", "disabled");
    document.getElementById("searchBtn").setAttribute("disabled", "disabled");
    getSweepstakesData(dispatch, e.target.value, state.walletAddressEth, state.walletAddressOne);
    document.getElementById("searchField").removeAttribute("disabled");
    document.getElementById("searchBtn").removeAttribute("disabled");
  }

  useEffect(() => {
    createEpochDropdownOptions();
  }, []);

  return (
    <section className="block-explorer-features section bg-bottom" id="sweepstakes">
      <div className="container">
        <div className="row">
          <div className="center-heading">
          <p>Tickets for the new Epoch will be generated within an hour of the start of each Epoch. Please wait for the website to be refreshed. If you have any questions, please reach out to us at our <a href="/#contact">Telegram Channel</a></p>
          <br/><br/>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 left-heading">
            <div className="left-text-no-bottom-margin">
              <p className="white">{state.drawCreateDateTime}</p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 right-heading">
            <div>
              <select id="result-epoch-combobox" className="result-epoch-combobox" onChange={handleEpochDropdownOnChange} value={state.drawNo}>
                {items}
              </select>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm12 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="box">
              <div className="draw-details">
                <h1 className="header">
                  EPOCH {state.drawNo}
                  <br />
                  <br />
                  <span className="yellow">JACKPOT: {ethers.utils.commify(parseFloat(ethers.utils.formatUnits(state.potSize, 18)).toFixed(6))} $ONE</span>
                </h1>
                <div className="table-responsive">
                  <table className="table table-striped table-draw">
                    <thead>
                      <tr>
                        <td colSpan={6}>
                          <b>Winning Numbers</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {state.winningNumbers == null || state.winningNumbers.length == 0 || state.winningNumbers[0] == 0 ? (
                          <td colspan={6}>Draw is on-going. Winning numbers will be generated at end of the Epoch.</td>
                        ) : (
                          state.winningNumbers.map((i) => (
                            <td>
                              <b>{ethers.utils.formatUnits(i, 0)}</b>
                            </td>
                          ))
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                {state.winningNumbers == null || state.winningNumbers.length == 0 || state.winningNumbers[0] == 0 ? (
                  <></>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-striped table-draw">
                      <thead>
                        <tr>
                          <td colSpan={4}>
                            <b>Winning Shares</b>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>Prize Group</b>
                          </td>
                          <td>
                            <b>Share Per Ticket</b>
                          </td>
                          <td>
                            <b>No. of Winners</b>
                          </td>
                          <td>
                            <b>Address</b>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Jackpot
                            <br />
                            (6 matching nos)
                          </td>
                          <td>{state.prizeGroup1 != null ? ethers.utils.commify(parseFloat(state.prizeGroup1["prizePerTicket"]).toFixed(6)) : 0} $ONE</td>
                          <td>{state.prizeGroup1 != null ? state.prizeGroup1["tickets"].length : 0}</td>
                          <td>
                            {state.prizeGroup1 != null ? (
                              state.prizeGroup1["tickets"].map((i) => (
                                <>
                                  <a href={"https://explorer.harmony.one/address/"+i["buyer"]+"?activeTab=2"} target="_blank" rel="noreferrer noopener">{getAddress(i["buyer"]).bech32}</a> (Ticket No {i["ticketNum"]})<br />
                                </>
                              ))
                            ) : (
                              <>No winners</>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Group 5<br />
                            (5 matching nos)
                          </td>
                          <td>{state.prizeGroup2 != null ? ethers.utils.commify(parseFloat(state.prizeGroup2["prizePerTicket"]).toFixed(6)) : 0} $ONE</td>
                          <td>{state.prizeGroup2 != null ? state.prizeGroup2["tickets"].length : 0}</td>
                          <td>
                            {state.prizeGroup2 != null ? (
                              state.prizeGroup2["tickets"].map((i) => (
                                <>
                                  <a href={"https://explorer.harmony.one/address/"+i["buyer"]+"?activeTab=2"} target="_blank" rel="noreferrer noopener">{getAddress(i["buyer"]).bech32}</a> (Ticket No {i["ticketNum"]})<br />
                                </>
                              ))
                            ) : (
                              <>No winners</>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Group 4<br />
                            (4 matching nos)
                          </td>
                          <td>{state.prizeGroup3 != null ? ethers.utils.commify(parseFloat(state.prizeGroup3["prizePerTicket"]).toFixed(6)) : 0} $ONE</td>
                          <td>{state.prizeGroup3 != null ? state.prizeGroup3["tickets"].length : 0}</td>
                          <td>
                            {state.prizeGroup3 != null ? (
                              state.prizeGroup3["tickets"].map((i) => (
                                <>
                                  <a href={"https://explorer.harmony.one/address/"+i["buyer"]+"?activeTab=2"} target="_blank" rel="noreferrer noopener">{getAddress(i["buyer"]).bech32}</a> (Ticket No {i["ticketNum"]})<br />
                                </>
                              ))
                            ) : (
                              <>No winners</>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Group 3<br />
                            (3 matching nos)
                          </td>
                          <td>{state.prizeGroup4 != null ? ethers.utils.commify(parseFloat(state.prizeGroup4["prizePerTicket"]).toFixed(6)) : 0} $ONE</td>
                          <td>{state.prizeGroup4 != null ? state.prizeGroup4["tickets"].length : 0}</td>
                          <td>
                            {state.prizeGroup4 != null ? (
                              state.prizeGroup4["tickets"].map((i) => (
                                <>
                                  <a href={"https://explorer.harmony.one/address/"+i["buyer"]+"?activeTab=2"} target="_blank" rel="noreferrer noopener">{getAddress(i["buyer"]).bech32}</a> (Ticket No {i["ticketNum"]})<br />
                                </>
                              ))
                            ) : (
                              <>No winners</>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p>
            <br />
          </p>
          <div className="col-lg-12 col-md-12 col-sm12 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="box">
              <div className="draw-details">
                <h1 className="header">Your Assigned Tickets</h1>
                <div className="col-lg-12 center-heading">
                  <p>
                    <a href={"https://explorer.harmony.one/address/"+getAddress(state.walletAddressOne).basicHex+"?activeTab=2"} target="_blank" rel="noreferrer noopener">{state.walletAddressOne}</a>
                    <br />
                    <br />
                    <b>Total winnings for this draw:</b> {ethers.utils.commify(parseFloat(state.totalWinnings).toFixed(6))} $ONE
                    <br />
                    <br />
                    <b>Total winnings to date:</b> {ethers.utils.commify(parseFloat(state.winningsToDate).toFixed(6))} $ONE
                    <br />
                    <br />
                    {state.winningNumbers == null || state.winningNumbers.length == 0 || state.winningNumbers[0] == 0 ? (
                      <></>
                    ) : (
                      <>
                        <b>Winning Numbers:</b> {state.winningNumbers[0]} {state.winningNumbers[1]} {state.winningNumbers[2]} {state.winningNumbers[3]} {state.winningNumbers[4]}{" "}
                        {state.winningNumbers[5]}
                        <br />
                        <br />
                      </>
                    )}
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-draw">
                    <thead>
                      <tr>
                        <td>
                          <b>Ticket</b>
                        </td>
                        <td colSpan={6}>
                          <b>Numbers</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {state.assignedTickets == null || state.assignedTickets.length == 0 ? (
                        <td colSpan={7}>No assigned tickets.</td>
                      ) : (
                        state.assignedTickets.map((i) => (
                          <tr>
                            <td>
                              <b>{i["ticketNum"]}</b>
                            </td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][0]) ? <mark>{i["chosenNums"][0]}</mark> : i["chosenNums"][0]}</td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][1]) ? <mark>{i["chosenNums"][1]}</mark> : i["chosenNums"][1]}</td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][2]) ? <mark>{i["chosenNums"][2]}</mark> : i["chosenNums"][2]}</td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][3]) ? <mark>{i["chosenNums"][3]}</mark> : i["chosenNums"][3]}</td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][4]) ? <mark>{i["chosenNums"][4]}</mark> : i["chosenNums"][4]}</td>
                            <td>{state.winningNumbersSet.has(i["chosenNums"][5]) ? <mark>{i["chosenNums"][5]}</mark> : i["chosenNums"][5]}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p>
            <br />
          </p>
          <div className="col-lg-12 col-md-12 col-sm12 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="box">
              <div className="draw-details">
                <h1 className="header">Winning History</h1>
                <div className="table-responsive">
                  <table className="table table-striped table-draw">
                    <thead>
                      <tr>
                        <td>
                          <b>Epoch</b>
                        </td>
                        <td>
                          <b>Prize Group</b>
                        </td>
                        <td>
                          <b>Ticket</b>
                        </td>
                        <td>
                          <b>Prize ($ONE)</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {state.winningHistory == null || state.winningHistory.length == 0 ? (
                        <td colSpan={4}>No past winnings.</td>
                      ) : (
                        state.winningHistory.map((history) => (
                          <tr>
                            <td>{history["drawNo"]}</td>
                            <td>{history["groupNum"]}</td>
                            <td>{history["ticketNo"]}</td>
                            <td>{parseFloat(history["prize"]).toFixed(6)}</td>
                         </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
