import React, {useContext} from 'react';

import { Header } from "./header.js";
import { WelcomeArea } from "./welcome_area.js";
import { Sweepstakes } from "./sweepstakes";
import { Footer } from "./footer.js";
import { GameMechanics } from "./game_mechanics";
import { SweepstakeFunding } from "./sweepstake_funding";
import { TicketStructure } from "./ticket_structure";
import { PrizeStructure } from "./prize_structure";
import { EarlyDelegatorBonus } from "./early_delegator_bonus";
import { FAQArea } from './faq_area.js';
import { AppContext } from "./../../index";

function Validera() {
  const { state, dispatch } = useContext(AppContext);
    return (
      <div>
        <Header />
        <WelcomeArea/>
        <Sweepstakes />
        <GameMechanics />
        <SweepstakeFunding />
        <TicketStructure />
        <PrizeStructure />
        <EarlyDelegatorBonus />
        <FAQArea />
        <Footer />
      </div>
    );
}

export default Validera;