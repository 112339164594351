import React from "react";

export function SocialMediaArea() {
  return (
    <section className="parallax">
		<div className="parallax-content">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h1 className="section-title">We are on Telegram and Twitter</h1>
					</div>
					<div className="offset-lg-3 col-lg-6">
						<p>Ask us anything & share your ideas</p>
					</div>
					<div className="offset-lg-3 col-lg-6">
						<a href="https://t.me/joinchat/SgLyFvOLm9MxNWIx" target="_blank" rel="noreferrer noopener" className="btn-white-line" data-scroll-reveal="enter bottom move 30px over 0.6s after 0.2s">Telegram</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<a href="https://twitter.com/quasarprojectio" target="_blank" rel="noreferrer noopener" className="btn-white-line" data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s">Twitter</a>
					</div>
				</div>
			</div>
		</div>
	</section>
  );
}
