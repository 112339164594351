import React from "react";

export function WelcomeArea() {
  return (
    <section className="welcome-area" id="welcome-1">
      <div className="header-token basic">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
              <h1>The Quasar Project</h1>
              <p>Welcome to the world of Quasar. We are a community driven project with the core objective of creating fundamental value and utility on the blockchain while ensuring 100% trust and transparency with the community. </p>
              {/* <p>As we embark on our ambitious roadmap, we empower the community to make strategic decisions and design the world of Quasar. The power is yours. </p> */}
              <p>The Quasar project is powered by the Harmony One blockchain and our native governance token is QUASI.</p>
              <a href="#roadmap" className="btn-secondary-box">VIEW OUR ROADMAP</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
              <div className="box">
                <div className="token">
                  <h6 className="title">COUNTDOWN TO PHASE 2B LAUNCH (SWEEPSTAKE DRAW)</h6>
                  <ul className="countdown">
                    <li>
                      <span className="days">00</span>
                      <p className="days_ref">days</p>
                    </li>
                    <li className="seperator"></li>
                    <li>
                      <span className="hours">00</span>
                      <p className="hours_ref">hours</p>
                    </li>
                    <li className="seperator"></li>
                    <li>
                      <span className="minutes">00</span>
                      <p className="minutes_ref">minutes</p>
                    </li>
                    <li className="seperator"></li>
                    <li>
                      <span className="seconds">00</span>
                      <p className="seconds_ref">seconds</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-to">
        <a href="#quasarworld">
          <i className="fa fa-angle-double-down" aria-hidden="true"></i>
        </a>
      </div>
    </section>
  );
}
