import React, { useReducer } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import Validera from "./components/validera/validera";
import SweepstakesResult from "./components/sweepstakes_result/sweepstakes_result";
import update from "immutability-helper";

export const AppContext = React.createContext();

const initialState = {
  walletAddressOne: "",
  walletAddressEth: "",
  error: "",
  latestDrawNo: 0,
  drawNo: 0,
  hasError: false,
  isLoaded: false,
  winningNumbers: [],
  winningNumbersSet: new Set(),
  drawCreateDateTime: "",
  assignedTickets: null,
  prizeGroup1: null,
  prizeGroup2: null,
  prizeGroup3: null,
  prizeGroup4: null,
  potSize: 0,
  totalWinnings: 0,
  daysLeftEpoch: 0,
  hrsLeftEpoch: 0,
  minsLeftEpoch: 0,
  secsLeftEpoch: 0,
  winningsToDate: 0,
  winningHistory: [],
  winningStats: null
};

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_SSR_WALLET_ADDRESS_ONE":
      return update(state, { walletAddressOne: { $set: action.data } });
    case "UPDATE_SSR_WALLET_ADDRESS_ETH":
      return update(state, { walletAddressEth: { $set: action.data } });
    case "UPDATE_SSR_ERROR":
      return update(state, { error: { $set: action.data } });
    case "UPDATE_SSR_HAS_ERROR":
      return update(state, { hasError: { $set: action.data } });
    case "UPDATE_SSR_POT_SIZE":
      return update(state, { potSize: { $set: action.data } });
    case "UPDATE_SSR_DRAW":
      return update(state, { drawNo: { $set: action.data } });
    case "UPDATE_SSR_LATEST_DRAW":
      return update(state, { latestDrawNo: { $set: action.data } });
    case "UPDATE_SSR_ISLOADED":
      return update(state, { isLoaded: { $set: action.data } });
    case "UPDATE_SSR_WINNINGNUMERS":
      return update(state, { winningNumbers: { $set: action.data } });
    case "UPDATE_SSR_WINNINGNUMBERS_SET":
      return update(state, { winningNumbersSet: { $set: action.data } });
    case "UPDATE_SSR_DRAWDATETIME":
      return update(state, { drawCreateDateTime: { $set: action.data } });
    case "UPDATE_SSR_ASSIGNEDTICKETS":
      return update(state, { assignedTickets: { $set: action.data } });
    case "UPDATE_SSR_PRIZEGROUP1":
      return update(state, { prizeGroup1: { $set: action.data } });
    case "UPDATE_SSR_PRIZEGROUP2":
      return update(state, { prizeGroup2: { $set: action.data } });
    case "UPDATE_SSR_PRIZEGROUP3":
      return update(state, { prizeGroup3: { $set: action.data } });
    case "UPDATE_SSR_PRIZEGROUP4":
      return update(state, { prizeGroup4: { $set: action.data } });
    case "UPDATE_SSR_TOTALWINNINGS":
      return update(state, { totalWinnings: { $set: action.data } });
    case "UPDATE_DAYS_EPOCH":
      return update(state, { daysLeftEpoch: { $set: action.data } });
    case "UPDATE_HRS_EPOCH":
      return update(state, { hrsLeftEpoch: { $set: action.data } });
    case "UPDATE_MINS_EPOCH":
      return update(state, { minsLeftEpoch: { $set: action.data } });
    case "UPDATE_SECS_EPOCH":
      return update(state, { secsLeftEpoch: { $set: action.data } });
    case "UPDATE_WINNINGS_TO_DATE":
      return update(state, { winningsToDate: { $set: action.data } });
    case "UPDATE_WINNING_HISTORY":
      return update(state, { winningHistory: { $set: action.data } });
    case "UPDATE_WINNING_STATS":
      return update(state, { winningStats: { $set: action.data } });
    default:
      return initialState;
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="/validera" element={<Validera />} />
          <Route path="/valideraSweepstakeResults" element={<SweepstakesResult />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

export default App;
