import React from "react";

export function ICOArea() {
  return (
    <section className="section gradient" id="harmonyvalidator">
      <div className="container">
        <div className="row m-bottom-70">
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="features-elliptical elliptical-bottom" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
              <div className="icon">
                <i className="fa fa-globe"></i>
              </div>
              <h5 className="features-title">The Quasar World</h5>
              <p>Explore the world of Quasar utilities and games</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="features-elliptical elliptical-top" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
              <div className="icon">
                <i className="fa fa-cube"></i>
              </div>
              <h5 className="features-title">Harmony Validator & Sweepstake</h5>
              <p>New validator on the block with daily sweepstake!</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="features-elliptical" data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
              <div className="icon">
                <i className="fa fa-usd"></i>
              </div>
              <h5 className="features-title">The QUASI token</h5>
              <p>Tokenomics, governance and rewards</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 align-self-center">
            <div className="left-heading">
              <h2 className="section-title">Validera - Harmony Validator & Sweepstake for Delegators</h2>
            </div>
            <div className="left-text">
              <p className="dark">
                The people of Validera are building a new validator node on Harmony. As adopters and promoters of the Harmony blockchain, their objective is to push for a more decentralized and fairly
                balanced validator pool in the Harmony Open Staking protocol. Join their movement!
              </p>
              <p className="dark">
                For the first 100 epochs (now till ~mid-Feb 2022) where Validera is elected, Validera will charge 0% commission. Subsequently, Validera will be required to charge a 5% commission as
                mandated by the Harmony protocol. <br />
                <br />
                But fret not, this 5% will be put to good use! We believe in giving back to the community and our delegates will stand a chance to win even more, and win big!
              </p>
              <p>
                Click on the below link to stake your ONEs with Validera on the Harmony Blockchain. <br />
                Read more to understand the sweepstake mechanics
              </p>
            </div>

            <div className="btn-group">
              <a href="https://staking.harmony.one/validators/mainnet/one1jfe5c30pcyxnzktqfv7w7qqnxysm5r3hlgcgq4" target="_blank" rel="noreferrer noopener" className="btn-white-line">
                Stake with us
              </a>
              <a href="/validera" className="btn-white-line">
                Sweepstake Mechanics
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
            <center>
              <img src="assets/images/HarmonyQS_Partnership.png" className="img-fluid float-right" alt="Harmony + Quasar Partnership" />
            </center>
          </div>
        </div>
      </div>
    </section>
  );
}
