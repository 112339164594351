import React from "react";

export function ContactFooterArea() {
  return (
    <footer id="contact">
      <div className="footer-bottom slim">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="copyright">2021 © The Quasar Project - https://quasarproject.io</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
