import React from "react";

export function RoadmapArea() {
  return (
    <section className="section" id="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="center-heading">
              <h2 className="section-title">Roadmap</h2>
            </div>
          </div>
          <div className="offset-lg-2 col-lg-8">
            <div className="center-text">
              <p>The Quasarians have embarked on an ambitious journey.
               <br />A strong foundation will be laid for the Quasar world in the coming months.
               <br /> As we venture ahead, the community will chart the future of the Quasar world and its community </p>
            </div>
          </div>
        </div>
      </div>
      <div className="roadmap-full">
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Nov 2021</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status complate"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p ><b>Phase 1- Validera</b></p> 
                <p>Harmony Validator Node</p>
                <p>Quasar Project Landing Page</p>
                <p>Telegram & Reddit</p>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Dec 2021</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status complate"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 2a- Validera Sweepstake</b></p>
                <p>Sweepstake Trustless Smart Contract</p>
                <p>Finalize Ticket and Prize Structure for Delegators</p>
                <p>Finalize Early Delegators Bonus</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Feb 2022</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status complate"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 2b- Validera Sweepstake First Draw</b></p>
                <p>First sweepstake draw (Epoch 874 - 14 Feb 2022)</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
         {/* <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Mar 2022</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 3- Gambledon</b></p>
                <p>The Gambledon ONE Lottery</p> 
                <p>Quasar World Landing Site</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Apr 2022</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 3- Priva</b></p>
                <p>Privacy manager on Harmony (a variation of ZK-STARK implementation)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Dec 2022</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 4- Avalon powered by Quasi</b></p>
                <p>Quasi Smart Contract & Tokenomics</p>                
                <p>Details of Avalon will be shared at a later date</p>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item-full" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.3s">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <h6 className="date">Jan 2023</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 position-relative">
                <div className="status"></div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <p><b>Phase 5- Councilion (DAO)</b></p>
                <p>First Community Vote in the Quasar World</p>
              </div>
            </div>
          </div>
        </div>              
      </div> 
    </section>
  );
}
