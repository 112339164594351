import React from "react";

export function FAQArea() {
  return (
    <section className="section bg-top" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="center-heading">
              <h1 className="section-title">Sweepstake FAQ</h1>
            </div>
          </div>
          <div className="offset-lg-3 col-lg-6">
            <div className="center-text">
              <p></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="heading-1">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                    <span className="badge">1</span> Why is there a need for Validera Sweepstake?
                  </button>
                </h2>
                <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>Harmony Protocol requires all validators to charge a minimum 5% commission fee after the first 100 epoch of being elected. To give back a percentage of the commission to our delegators, we have decided to run a sweepstake every epoch to give delegators a chance to win more, and win big. </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-2">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-1">
                    <span className="badge">2</span> What if there are no winners?
                  </button>
                </h2>
                <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>If there are no winners, the sweepstake fund gets rolled over to the next draw (i.e. next epoch)</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-3">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-2">
                    <span className="badge">3</span> How often is each draw?
                  </button>
                </h2>
                <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>A new sweepstake round starts every Epoch (~18.2 hrs)</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-4">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-3">
                    <span className="badge">4</span> Can I buy more tickets to increase my chances of winning?
                  </button>
                </h2>
                <div id="collapse-4" className="accordion-collapse collapse" aria-labelledby="heading-4" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>Not at this moment. We may at some point in the future allow users to buy tickets directly using $ONE. Do let us have your feedback in Telegram if you would like this feature! </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-5">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-4">
                    <span className="badge">5</span> How are the ticket and winning numbers generated?
                  </button>
                </h2>
                <div id="collapse-5" className="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>Validera Sweepstake smart contract utilizes Harmony protocol's native Verifiable Random Function (VRF) to guarantee true randomness. You may refer to the following link for more information: <a href="https://medium.com/harmony-one/introducing-harmony-vrf-4fc51e175c2" target="_blank">https://medium.com/harmony-one/introducing-harmony-vrf-4fc51e175c2</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
