import React from "react";

export function TicketStructure() {
  return (
    <section className="section gradient" id="ticket_structure">
      <div className="container">
        <div className="row">
          <div className="col-lg-3" data-scroll-reveal="enter left move 30px over 0.6s after 0.2s">
            <div className="center-heading">
              <div className="sweepstake_icon">
                <img src="assets/images/sweepstakes/ticket_structure.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
            <div className="left-heading">
              <h2 className="section-title">Ticket Structure</h2>
            </div>
            <div className="left-text">
              <p className="dark">
                <b>Distribution of tickets is linear proportional to the staked amount, up till a maximum of 10,000 ONEs. This is to ensure a fair balance between delegators with a high stake vs the majority of the remaining delegators</b>
                <br />
                <br />
                Receive 1 ticket for all delegations up to 1000 ONEs
                <br />
                Receive 1 ticket for every multiple of 1000 ONEs up to a maximum of 10,000 ONEs delegated
                <br />
                <br />
                For e.g.
                <ul>
                  <li>Stake 100 ONEs - Receive 1 ticket</li>
                  <br />
                  <li>Stake 500 ONEs - Receive 1 ticket</li>
                  <br />
                  <li>Stake 2,000 ONEs - Receive 2 tickets</li>
                  <br />
                  <li>Stake 10,000 ONEs - Receive 10 tickets</li>
                  <br />
                  <li>Stake 50,000 ONEs - Receive 10 tickets</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
