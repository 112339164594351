import React from "react";

export function PrizeStructure() {
  return (
    <section className="section gradient_white_opaque" id="prize_structure">
      <div className="container">
        <div className="row">
          <div className="col-lg-3" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="center-heading">
              <div className="sweepstake_icon">
                <img src="assets/images/sweepstakes/prize_structure.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
            <div className="left-heading">
              <h2 className="section-title">Prize Structure</h2>
            </div>
            <div className="left-text">
              <div className="table-responsive">
                <table className="table table-striped table-latests">
                  <thead>
                  <tr>
                    <td>
                      <b>Prize Group</b>
                    </td>
                    <td>
                      <b>Numbers Matched</b>
                    </td>
                    <td>
                      <b>Prize Amount</b>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      Group 6 <b>(Jackpot)</b>
                    </td>
                    <td>6 Winning Numbers</td>
                    <td>50% of prize pool</td>
                  </tr>
                  <tr>
                    <td>Group 5</td>
                    <td>5 Winning Numbers</td>
                    <td>7% of prize pool</td>
                  </tr>
                  <tr>
                    <td>Group 4</td>
                    <td>4 Winning Numbers</td>
                    <td>3% of prize pool</td>
                  </tr>
                  <tr>
                    <td>Group 3</td>
                    <td>3 Winning Numbers</td>
                    <td>1% of prize pool</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <p className="dark">
                <br />
                *If there are multiple winners in the same group, the prize will be split evenly among all winners in that group
                <br /><br />
                **At least 39% of the prize pool will roll-over to the next draw. This is to ensure there is always a sizable sweepstake fund in the pot and winnings also grow larger over time.
                <br /><br />
                ***Percentage of prize pool may be adjusted periodically to account for changes in the overall staking rewards for Validera, number of delegates and pot size
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
