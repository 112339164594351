import React from "react";

export function Header() {
  return (
    <header className="header-area">
      <div className="container">
        <div className="row">
          <div className="col-12 position-relative">
            <nav className="main-nav">
              <a href="#" className="logo">
                <img src="assets/images/logos/qs_logo.svg" className="light-logo" alt="The Quasar Project" />
                <img src="assets/images/logos/qs_logo.svg" className="dark-logo" alt="The Quasar Project" />
              </a>
              <ul className="nav_logo">
                <li><a href="/">THE QUASAR PROJECT</a></li>
              </ul>
              <ul className="nav">
              <li><a href="/validera">VALIDERA SWEEPSTAKES</a></li>
                <li><a href="/validera#game_mechanics">GAME MECHANICS</a></li>
                <li><a href="/validera#ticket_structure">TICKET & PRIZE STRUCTURE</a></li>
                <li><a href="/validera#early_delegators">EARLY DELEGATOR BONUS</a></li>
                <li><a href="/validera#faq">FAQ</a></li>
                <li><a href="https://staking.harmony.one/validators/mainnet/one1jfe5c30pcyxnzktqfv7w7qqnxysm5r3hlgcgq4" target="_blank" className="mobile btn-secondary-box">STAKE WITH US</a></li>
              </ul>
              <a className='menu-trigger'>
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
