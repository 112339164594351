import React from "react";

export function AppListArea() {
  return (
    <section className="section bg-bottom" id="quasarworld">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix">
            <div className="left-heading light">
              <h2 className="section-title">The Quasar World</h2>
            </div>
            <div className="left-text light m-bottom-0">
              <p>Enter into a world of utilities and games, community and government. With Quasi as the native token, stand a chance to win big, play and earn rewards, contribute to the community and plan the future for all Quasarians. </p>
              {/* <p>Holders of Quasi also govern the Quasar roadmap, and will drive community projects ahead </p> */}

              {/* <p>The story of Quasar starts in the realm of Validera, the first and the most utilitarian community on the planet. Soon enough, the other realms get ready to join the revolution</p> */}
              <p>Join the revolution, and witness the different realms of Quasar yourself-</p>
              
              <ul>
                <li>Validera- Harmony Validator with auto-sweepstake for delegators</li>
                <li>Priva- your privacy manager on Harmony (a variation of ZK-STARK implementation - whitepaper coming soon)</li>
                <li>Avalon- one game one religion (details coming soon)</li>                
                {/* <li>Gambledon- the realm of high stakes comes with a pot of luck</li>      */}
                <li>Councilion- the new capital brings a new form of government (DAO)</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.4s after 0.2s">
            <img src="assets/images/quasar4.PNG" className="img-fluid float-right" alt="Quasar World"/>
          </div>
        </div>
      </div>
    </section>
  );
}
