import React from "react";
import Tooltip from "react-simple-tooltip";

export function Header() {
  return (
    <header className="header-area">
      <div className="container">
        <div className="row">
          <div className="col-12 position-relative">
            <nav className="main-nav">
              <a href="#" className="logo">
                <img src="assets/images/logos/qs_logo.svg" className="light-logo" alt="The Quasar Project" />
                <img src="assets/images/logos/qs_logo.svg" className="dark-logo" alt="The Quasar Project" />
              </a>
              <ul className="nav_logo">
                <li><a href="/">THE QUASAR PROJECT</a></li>
              </ul>
              <ul className="nav">
              <li><a href="/#quasarworld">THE QUASAR WORLD</a></li>
                <li><a href="/validera">HARMONY VALIDATOR</a></li>
                <li><a href="/#tokenomics">TOKENOMICS</a></li>
                <li><a href="/#roadmap">ROADMAP</a></li>
                <li><a href="/#team">TEAM</a></li>
                <li><a href="/#contact">SOCIAL MEDIA</a></li>
                <li>
                  <Tooltip
                    arrow={1}
                    background="#747FEB"
                    border="#747FEB"
                    color="#fff"
                    content="Coming Soon"
                    fadeDuration={0}
                    fadeEasing="linear"
                    fixed={false}
                    fontFamily="sans-serif"
                    fontSize="14px"
                    offset={0}
                    padding={2}
                    placement="bottom"
                    radius={0}
                    zIndex={1}
                  >
                    <a href="#" className="mobile btn-secondary-box">ENTER WORLD OF QUASAR</a>
                  </Tooltip></li>
              </ul>
              <a className='menu-trigger'>
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
