import React, {useContext} from "react";
import { Header } from "../validera/header.js";
import { WelcomeArea } from "../validera/welcome_area.js";
import { DrawDetails } from "../sweepstakes_result/draw_details.js";
import { Footer } from "../validera/footer.js";
import { AppContext } from "./../../index";

function SweepstakesResult() {
  const { state, dispatch } = useContext(AppContext);

  return (
    <div>
      <Header />
        <WelcomeArea />        
        {state.isLoaded ? <DrawDetails /> : (state.hasError) ? <div className="center-text"><p className="yellow"><br/><br/><br/>{state.error}</p></div> : <span><br/><br/><br/><br/><br/><center><img src="assets/preloaders/ball-triangle.svg" alt="" /><br/></center><br/><br/><br/></span>}
      <Footer />
    </div>
  );
}

export default SweepstakesResult;
