import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { getAddress } from "@harmony-js/crypto";
import { AppContext } from "./../../index";
import { useNavigate } from "react-router-dom";

//export var config = require("./../../configs/config_testnet.json");
export var config = require("./../../configs/config_mainnet.json");
//export var config = require("./../../configs/config_local.json");
var provider = new ethers.providers.JsonRpcProvider(config.hmy_rpc_url);
const sweepstakeArtifact = require("./../../contracts/ValideraSweepstakeArtifact.json");
var sweepstakeContract = new ethers.Contract(config.swp_contract_addr, sweepstakeArtifact.abi, provider);

var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");

var myInit = {
  method: "GET",
  headers: myHeaders,
};

export const getDrawData = async (dispatch, drawNo) => {
  const changePotSizeValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_POT_SIZE", data: newValue });
  };
  const changeDrawDateTimeValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_DRAWDATETIME", data: newValue });
  };
  const changeWinningNumValue = (newValue, newValue2) => {
    dispatch({ type: "UPDATE_SSR_WINNINGNUMERS", data: newValue });
    dispatch({ type: "UPDATE_SSR_WINNINGNUMBERS_SET", data: newValue2 });
  };
  const changeDrawValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_DRAW", data: newValue });
  };
  const changeDaysLeftEpoch = (newValue) => {
    dispatch({ type: "UPDATE_DAYS_EPOCH", data: newValue });
  };
  const changeHoursLeftEpoch = (newValue) => {
    dispatch({ type: "UPDATE_HRS_EPOCH", data: newValue });
  };
  const changeMinutesLeftEpoch = (newValue) => {
    dispatch({ type: "UPDATE_MINS_EPOCH", data: newValue });
  };
  const changeSecondsLeftEpoch = (newValue) => {
    dispatch({ type: "UPDATE_SECS_EPOCH", data: newValue });
  };
  const changeWinningsStatsValue = (newValue) => {
    dispatch({ type: "UPDATE_WINNING_STATS", data: newValue });
  };

  async function fetchSweepstakesData() {
    const sweepstakesPromise = sweepstakeContract.getSweepstakeResultsForWebDisplay1(drawNo);
    const blockNumberPromise = provider.send("hmyv2_blockNumber", []);
    const epochPromise = provider.send("hmyv2_getEpoch", []);
    const statsPromise = fetch(config.swp_datafile_historyStats, myInit);

    try {
      let sweepstakesData = await sweepstakesPromise;
      let currentBlock = await blockNumberPromise;
      let epoch = await epochPromise;

      // process sweepstakes data
      var winningNos = sweepstakesData[0];
      var drawCreateDate = sweepstakesData[1];
      var potSize = sweepstakesData[2];

      var winningResult = null;
      if (winningNos != null && typeof winningNos != "undefined") {
        winningResult = [...winningNos];

        winningResult.sort(function (a, b) {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
      }
      //console.log("drawNo: " + drawNo);

      let winningNumbersSet = new Set();
      for (let i = 0; i < winningResult.length; i++) {
        winningNumbersSet.add(winningResult[i]);
      }

      // process lottery stats
      try {
        const statsReponse = await statsPromise;
        const stats = await statsReponse.json();
        changeWinningsStatsValue(stats);
      }
      catch (error) { changeWinningsStatsValue(null); }

      // process epoch/blocknumber
      if (drawNo == epoch) {
        //blocksize * currentEpoch-firstEpoch+1
        var epochLastBlock = 32768 * (epoch - 173 + 1);
        var secondsLeftEpoch = (epochLastBlock - currentBlock) * 2; // 2 sec block time

        const interval = setInterval(() => {
          if (secondsLeftEpoch <= 0) return;

          secondsLeftEpoch--;

          var days = Math.floor(secondsLeftEpoch / (3600 * 24));
          var hrs = Math.floor((secondsLeftEpoch - days * 3600 * 24) / 3600);
          var mins = Math.floor((secondsLeftEpoch - days * 3600 * 24 - hrs * 3600) / 60);
          var secs = secondsLeftEpoch - days * 3600 * 24 - hrs * 3600 - mins * 60;

          changeSecondsLeftEpoch(secs);
          changeMinutesLeftEpoch(mins);
          changeHoursLeftEpoch(hrs);
          changeDaysLeftEpoch(days);
        }, 1000);
      } else {
        changeSecondsLeftEpoch(0);
        changeMinutesLeftEpoch(0);
        changeHoursLeftEpoch(0);
        changeDaysLeftEpoch(0);
      }

      changePotSizeValue(potSize);
      changeWinningNumValue(winningResult, winningNumbersSet);

      changeDrawDateTimeValue(drawCreateDate);
      changeDrawValue(drawNo);
    } catch (error) { }
  }

  fetchSweepstakesData();
};

export const getSweepstakesData = (dispatch, drawNo, ethAddress, oneAddress) => {
  const changeHasError = (newValue) => {
    dispatch({ type: "UPDATE_SSR_HAS_ERROR", data: newValue });
  };
  const changeIsLoaded = (newValue) => {
    dispatch({ type: "UPDATE_SSR_ISLOADED", data: newValue });
  };
  const changeAssignedTicketsValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_ASSIGNEDTICKETS", data: newValue });
  };
  const changePrizeGroup1Value = (newValue) => {
    dispatch({ type: "UPDATE_SSR_PRIZEGROUP1", data: newValue });
  };
  const changePrizeGroup2Value = (newValue) => {
    dispatch({ type: "UPDATE_SSR_PRIZEGROUP2", data: newValue });
  };
  const changePrizeGroup3Value = (newValue) => {
    dispatch({ type: "UPDATE_SSR_PRIZEGROUP3", data: newValue });
  };
  const changePrizeGroup4Value = (newValue) => {
    dispatch({ type: "UPDATE_SSR_PRIZEGROUP4", data: newValue });
  };
  const changeTotalWinningsValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_TOTALWINNINGS", data: newValue });
  };
  const changeErrorValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_ERROR", data: newValue });
  };
  const changeWinningsToDateValue = (newValue) => {
    dispatch({ type: "UPDATE_WINNINGS_TO_DATE", data: newValue });
  };
  const changeWinningsHistoryValue = (newValue) => {
    dispatch({ type: "UPDATE_WINNING_HISTORY", data: newValue });
  };
  
  changeHasError(false);
  changeIsLoaded(false);

  sweepstakeContract.getSweepstakeResultsForWebDisplay1(drawNo).then((returnValue) => {
    getDrawData(dispatch, drawNo);

    async function fetchData() {
      const ticketPromise = fetch(config.swp_datafile_ticketMap.replace("{drawNo}", drawNo), myInit);
      const resultPromise = fetch(config.swp_datafile_resultMap.replace("{drawNo}", drawNo), myInit);
      const historyPromise = fetch(config.swp_datafile_historyMap.replace("{buyerAddress}", oneAddress), myInit);

      try {
        const ticketResponse = await ticketPromise;
        const tickets = await ticketResponse.json();

        var ticketArray = new Array();

        for (let i = 0; i < tickets.length; i++) {
          if (tickets[i]["buyer"] === oneAddress) {
            ticketArray.push(tickets[i]);
          }
        }

        try {
          const winnerResponse = await resultPromise;
          const winners = await winnerResponse.json();

          var group1Winner = null;
          var group2Winner = null;
          var group3Winner = null;
          var group4Winner = null;
          var consolidatedWinnings = 0;

          for (let i = 0; i < winners.length; i++) {
            if (winners[i]["groupNum"] == 6) {
              group1Winner = winners[i];
            } else if (winners[i]["groupNum"] == 5) {
              group2Winner = winners[i];
            } else if (winners[i]["groupNum"] == 4) {
              group3Winner = winners[i];
            } else if (winners[i]["groupNum"] == 3) {
              group4Winner = winners[i];
            }

            for (let j = 0; j < winners[i]["tickets"].length; j++) {
              if (winners[i]["tickets"][j]["buyer"] === oneAddress) {
                consolidatedWinnings += Number(winners[i]["prizePerTicket"]);
                //console.log(winners[i]["tickets"][j]["buyer"] + " " + oneAddress + " " + consolidatedWinnings);
              }
            }
          }

          changePrizeGroup1Value(group1Winner);
          changePrizeGroup2Value(group2Winner);
          changePrizeGroup3Value(group3Winner);
          changePrizeGroup4Value(group4Winner);
          changeTotalWinningsValue(consolidatedWinnings);
        }
        catch (error) { changeTotalWinningsValue(0); }

        try {
          const historyResponse = await historyPromise;
          const winningHistory = await historyResponse.json();
          
          winningHistory.winningHistory.sort(function(a, b){ return (b.drawNo - a.drawNo)});

          changeWinningsToDateValue(winningHistory.totalWinnings);
          changeWinningsHistoryValue(winningHistory.winningHistory);
        }
        catch (error) { changeWinningsToDateValue(0); changeWinningsHistoryValue([]); }

      } catch (error) {
        document.getElementById("searchField").removeAttribute("disabled");
        document.getElementById("searchBtn").removeAttribute("disabled");
        changeErrorValue("Data is unavailable.");
        changeHasError(true);
        changeIsLoaded(false);
      }

      changeAssignedTicketsValue(ticketArray);
      changeIsLoaded(true);
      document.getElementById("searchField").removeAttribute("disabled");
      document.getElementById("searchBtn").removeAttribute("disabled");
    }

    fetchData();    
  });
};

export function WelcomeArea() {
  const { state, dispatch } = useContext(AppContext);
  const changeWalletAddressOneValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_WALLET_ADDRESS_ONE", data: newValue.trim() });
  };
  const changeWalletAddressEthValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_WALLET_ADDRESS_ETH", data: newValue.trim() });
  };
  const changeErrorValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_ERROR", data: newValue });
  };
  const changeHasError = (newValue) => {
    dispatch({ type: "UPDATE_SSR_HAS_ERROR", data: newValue });
  };
  const changeLatestDrawValue = (newValue) => {
    dispatch({ type: "UPDATE_SSR_LATEST_DRAW", data: newValue });
  };
  const changeIsLoaded = (newValue) => {
    dispatch({ type: "UPDATE_SSR_ISLOADED", data: newValue });
  };
  const [inputValue, setInputValue] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    sweepstakeContract.getCurrentDrawNo().then((drawNo) => {
      getDrawData(dispatch, drawNo);
    });
  }, []);

  function handleOnClick(e) {
    if (inputValue === "" || document.getElementById("searchField").disabled == true) return;

    document.getElementById("searchField").setAttribute("disabled", "disabled");
    document.getElementById("searchBtn").setAttribute("disabled", "disabled");

    navigate("/valideraSweepstakeResults");

    try {
      var harmonyAddress = getAddress(state.walletAddressOne);
      var oneAddress = harmonyAddress.bech32;
      var ethAddress = ethers.utils.getAddress(harmonyAddress.basicHex);

      changeWalletAddressEthValue(ethAddress);
      changeWalletAddressOneValue(oneAddress);

      let currentDrawNo;
      sweepstakeContract
        .getCurrentDrawNo()
        .then((drawNo) => {
          currentDrawNo = drawNo;
          changeLatestDrawValue(currentDrawNo);
        })
        .then(() => {
          getSweepstakesData(dispatch, currentDrawNo, ethAddress, oneAddress);
        });
    } catch (err) {
      changeIsLoaded(false);
      changeErrorValue(err.message);
      changeHasError(true);
      document.getElementById("searchField").removeAttribute("disabled");
      document.getElementById("searchBtn").removeAttribute("disabled");
    }
  }

  return (
    <section className="block-explorer-wrapper bg-bottom-center" id="welcome-1">
      <div className="block-explorer text">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <h1>VALIDERA</h1>
              <div className="offset-lg-3 col-lg-6">
                <p>
                  Harmony Validator & Sweepstakes for Delegators
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="search">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="input-wrapper">
                <div className="input">
                  <input
                    id="searchField"
                    type="text"
                    placeholder="Enter address to view tickets assigned"
                    value={state.walletAddressOne.trim()}
                    onChange={(e) => changeWalletAddressOneValue(e.target.value.trim())}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        handleOnClick();
                      }
                    }}
                  />
                  <button id="searchBtn">
                    <i className="fa fa-search" onClick={handleOnClick}></i>
                    {/* <i className="fa fa-search"></i> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
