import React from "react";
import { config } from "./welcome_area";

export function EarlyDelegatorBonus() {
  return (
    <section className="section gradient_lightblue" id="early_delegators">
    <div className="container">
      <div className="row">
            <div className="col-lg-3" data-scroll-reveal="enter left move 30px over 0.6s after 0.2s">
              <div className="center-heading">
                <div className="sweepstake_icon">
                  <img src="assets/images/sweepstakes/early_delegators.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-9 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
              <div className="left-heading">
                <h2 className="section-title">Early Delegator Bonus</h2>
              </div>
              <div className="left-text">
                <p className="dark">
                  <b>Delegators who have staked with VALIDERA before 31 Dec 2021 will be awarded 2x bonus tickets for the first 50 epochs (until Epoch 924). Thereafter, your stake will follow the normal ticket distribution</b>
                  <br /><br />
                  Delegators will need to <u>remain delegated (and with the same stake or more)</u> with VALIDERA to qualify for the bonus
                  <br /><br />
                  <div className="btn-group">
                    <a href="assets/data/earlyDelegations.json" target="_blank" rel="noreferrer noopener" className="btn-white-line">
                    List of Early Delegators
                  </a>
                  </div>             
                </p>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}
