import React from "react";

export function GameMechanics() {
  return (
    <section className="section gradient" id="game_mechanics">
      <div className="container">
        <div className="row">
          <div className="col-lg-3" data-scroll-reveal="enter left move 30px over 0.6s after 0.2s">
            <div className="center-heading">
              <div className="sweepstake_icon">
                <img src="assets/images/sweepstakes/game_mechanics.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
            <div className="left-heading">
              <h2 className="section-title">Game Mechanics</h2>
            </div>
            <div className="left-text">
              <p className="dark">
                <b>To enrol into the sweepstakes, simply stake your ONEs with VALIDERA</b>
                <br/><br/>
                At the start of each epoch, every delegate will be assigned tickets based on their stake (see ticket structure)
                <br/><br/>
                Each ticket consists of 6 randomly generated numbers between 1 and 59
                <br/><br/>
                Draws take place at the end of every epoch (approximately 18.2hrs). 6 winning numbers are drawn each draw
                <br/><br/>
                Winners will receive ONEs directly into their wallet based on the prize structure (see below)
                <br/><br/>
                If there are no winners, the sweepstake fund gets rolled over to the next draw (i.e. next epoch)
                <br/><br/>
                There will be a dashboard on the website to view your assigned tickets, sweepstake fund and winning numbers
                </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
