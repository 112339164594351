import React from "react";

export function SweepstakeFunding() {
  return (
    <section className="section gradient_white_opaque" id="sweepstake_funding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="center-heading">
              <div className="sweepstake_icon">
                <img src="assets/images/sweepstakes/funding.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
            <div className="left-heading">
              <h2 className="section-title">Sweepstake Funding</h2>
            </div>
            <div className="left-text">
              <p className="dark">
                <b>The sweepstakes will be seeded with an initial fund of 2,000 ONEs</b>
                <br />
                <br />
                Once VALIDERA is required by the protocol to charge 5% commission, we will channel half of it (capped at 150 $ONE per Epoch) to the sweepstake fund
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
