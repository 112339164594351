import React, { useContext, useState } from "react";
import { AppContext } from "./../../index";
import { ethers } from "ethers";

export function Sweepstakes() {
  const { state, dispatch } = useContext(AppContext);

  return (
    <section className="block-explorer-features section bg-bottom" id="sweepstakes">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="left-heading">
              <h6 className="section-title">Validera Sweepstakes</h6>
            </div>
            <div className="left-text">
              <p className="dark">The Quasar project is pleased to announce VALIDERA, a one of its kind validator on the Harmony blockchain.</p>
              <p className="dark">
                In addition to staking rewards, delegators will auto-enroll into a sweepstakes game and stand a chance to win even more ONEs, perhaps even hit the jackpot! Validera will run a
                sweepstakes every epoch via a fully automated smart contract.
              </p>
              <p className="dark">You stake and we sweepstake!</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
            <div className="box">
              <div className="jackpot">
                <h1 className="jackpot_title">CURRENT DRAW - EPOCH {state.drawNo == 0 ? <img width="15" height="15" src="assets/preloaders/three-dots.svg" alt="" /> : state.drawNo}</h1>
                <div className="jackpot_box">
                  <p className="jackpot_box_title">Estimated Jackpot*</p>
                  <p className="jackpot_value">
                    {state.potSize == 0 ? (
                      <img width="50" height="50" src="assets/preloaders/three-dots.svg" alt="" />
                    ) : (
                      <>
                        <span className="jackpot_counter">{parseFloat(ethers.utils.formatUnits(state.potSize, 18)).toFixed(0)}</span>
                      </>
                    )}
                    <span className="one">$ONE</span>
                  </p>
                </div>
                {state.winningStats != null ? (
                <div className="jackpot_subtitle">
                      <p>
                        Total rewards paid out in {state.drawNo - 874} draws:{" "}
                        <span style={{ color: "white", fontSize: "larger" }}>
                          <b>{parseFloat(state.winningStats.totalWinningsPaidOut).toFixed(0)}</b></span> $ONE                        
                        <br />
                        Total contributions in Sweepstakes:{" "}
                        <span style={{ color: "white", fontSize: "larger" }}>
                          <b>{parseFloat(state.winningStats.totalContributionToPot).toFixed(0)}</b></span> $ONE
                        <br />
                        Total winners to date:{" "}
                        <span style={{ color: "white", fontSize: "larger" }}>
                          <b>{state.winningStats.totalWinnersToDate}</b>
                        </span>
                      </p>
                      <br />
                      <hr style={{ width: "60%", margin: "auto" }}></hr>
                      </div>
                  ) : (
                    <></>
                  )}
                {state.daysLeftEpoch == 0 && state.hrsLeftEpoch == 0 && state.minsLeftEpoch == 0 && state.secsLeftEpoch == 0 ? (
                  <>
                    <div className="jackpot_subtitle">
                      <br />
                      <p>
                        Waiting for next draw to begin. <br />
                        <br />
                        Winning numbers and tickets for the new Epoch will be generated within an hour. Please wait for the website to be refreshed.{" "}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="jackpot_subtitle">
                      <br />
                      <p>Countdown to next draw</p>
                    </div>
                    <ul className="jackpot_countdown">
                      <li>
                        <span className="hours">{state.daysLeftEpoch}</span>
                        <p className="days_ref">days</p>
                      </li>
                      <li className="seperator"></li>
                      <li>
                        <span className="hours">{state.hrsLeftEpoch}</span>
                        <p className="hours_ref">hours</p>
                      </li>
                      <li className="seperator"></li>
                      <li>
                        <span className="minutes">{state.minsLeftEpoch}</span>
                        <p className="minutes_ref">minutes</p>
                      </li>
                      <li className="seperator"></li>
                      <li>
                        <span className="seconds">{state.secsLeftEpoch}</span>
                        <p className="seconds_ref">seconds</p>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
