import React from "react";

export function TeamProfile() {
  return (
    <section className="section gradient" id="team">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="center-heading">
						<h2 className="section-title">Team</h2>
					</div>
				</div>
				<div className="offset-lg-2 col-lg-8">
					<div className="center-text">
						<p>Software developers with combined experience of 25 years. <br/>Blockchain enthusiasts, looking to bridge the gap between blockchain and the masses.</p>
					</div>					
				</div>
			</div>
			<div className="row">
				<div className="col-lg-3 col-md-3 col-sm-12 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
				</div>
				<div className="col-lg-3 col-md-3 col-sm-12 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
					<div className="team-round">
						<div className="profile">
							<div className="img">
								<img src="assets/images/quasar-juan.jpg" alt=""/>
								<ul className="social">
									<li><a href="https://twitter.com/quasarprojectio" target="_blank" rel="noreferrer noopener" ><i className="fa fa-twitter"></i></a></li>
									<li><a href="mailto:juan@quasarproject.io"><i className="fa fa-envelope-o"></i></a></li>
								</ul>
							</div>
						</div>
						<h3 className="team-name">Juan</h3>
						<span>Co-founder & Developer<br/>juan@quasarproject.io</span>
					</div>
				</div>
				<div className="col-lg-3 col-md-3 col-sm-12 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
					<div className="team-round">
						<div className="profile">
							<div className="img">
								<img src="assets/images/quasar-josh.jpg" alt=""/>
								<ul className="social">
								<li><a href="https://twitter.com/quasarprojectio" target="_blank" rel="noreferrer noopener" ><i className="fa fa-twitter"></i></a></li>								
								<li><a href="mailto:josh@quasarproject.io"><i className="fa fa-envelope-o"></i></a></li>								
								</ul>
							</div>
						</div>
						<h3 className="team-name">Josh</h3>
						<span>Co-founder & Developer<br/>josh@quasarproject.io</span>
					</div>
				</div>	
			</div>
		</div>
	</section>
  );
}
